export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCHA5ItKjjE5j6s4CgoAcjvCn-pI92Fkd8',
    authDomain: 'enroll-here.firebaseapp.com',
    projectId: 'enroll-here',
    storageBucket: 'enroll-here.appspot.com',
    messagingSenderId: '68638839047',
    appId: '1:68638839047:web:b54cc3adea9fe61afd35a2',
    measurementId: 'G-1LRMTQR7LV',
    appCheckRecaptchaSiteKey: '6LcCjqQpAAAAAIbfEHbweqiCW1o9CuxhBbIl7oZ9',
  },
  platformBaseUrl: 'https://importer.enrollhere.com',
  enrollhereBaseUrl: 'https://app.enrollhere.com',
  googleMapsApiKey: 'AIzaSyBH4UGIhjzbfoFtEHg9RKXq8tzXh65E4UE',
  isDemo: false,
  elasticHttp: 'https://enrollhere.es.us-east1.gcp.elastic-cloud.com/',
  elasticPrefix: 'enrollhere',
  devVersion: '{BUILD_VERSION}',
  sentry: {
    dsn: 'https://8c005538bd1220a2d2d801e97832093e@o4506558808064000.ingest.us.sentry.io/4506702212628480',
    tracePropagationTargets: [/^https:\/\/importer\.enrollhere\.com/],
    environment: 'development',
  },
};
